import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button/button.module';
import { IconModule } from '@components/icon/icon.module';
import { DialogService } from '../../../services/dialog.service';
import { ToggleService } from 'src/app/core/modules/toggle/toggle.service';
import { SignUpComponent } from 'src/app/modules/auth/components/signup/dialog/sign-up.component';
import { PromoBannerService } from 'src/app/core/services/promo-banner.service';
import { UserService } from 'src/app/core/services/user.service';
import { map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-promo-banner-signup',
  templateUrl: './promo-banner-signup.component.html',
  styleUrl: './promo-banner-signup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonModule, IconModule],
})
export class PromoBannerSignupComponent {
  @Input() id: string;

  showBanner$ = this.userService
    .getCurrentUser()
    .pipe(map(user => user == null));

  constructor(
    private readonly dialogService: DialogService,
    private readonly toggleService: ToggleService,
    private readonly promoBannerService: PromoBannerService,
    private readonly userService: UserService,
  ) {}

  onClose(event: Event) {
    event.stopPropagation();
    this.promoBannerService.closingBanners.add(this.id);
  }

  onSignUp() {
    this.dialogService.open(SignUpComponent);
  }

  get getSaveAmount() {
    return Math.round(
      this.toggleService.getVariantValue<number>('signUpRewardPoints') / 100,
    );
  }
}
