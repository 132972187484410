import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import {
  ButtonColor,
  ButtonVariant,
} from '@components/button/button.component';
import { DialogWidth } from '@components/dialog/dialog-wrapper.component';

export interface ConfirmationDialogData {
  dialogWidth?: DialogWidth;
  showCloseButton?: boolean;
  dialogHeader?: string | SafeHtml;
  confirmationText?: string;
  cancelButton?: ConfirmationButtonData;
  confirmButton?: ConfirmationButtonData;
}

export interface ConfirmationButtonData {
  text?: string;
  color?: ButtonColor;
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick?: () => void;
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  readonly largeScreen$: Observable<boolean> =
    this.breakpointService.largeScreen$;

  dialogWidth: DialogWidth = 'md';
  showCloseButton = true;
  dialogHeader: string | SafeHtml = 'Confirm';
  confirmationText: string | SafeHtml = 'Are you sure you want to continue?';
  confirmButton: ConfirmationButtonData = {
    text: 'Confirm',
    color: 'primary',
    variant: 'contained',
    onClick: () => this.closeDialog(true),
  };
  cancelButton: ConfirmationButtonData = {
    text: 'Cancel',
    color: 'secondary',
    variant: 'contained',
    onClick: () => this.closeDialog(),
  };

  constructor(
    private readonly breakpointService: BreakpointService,
    private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogData,
  ) {
    this.dialogWidth = data.dialogWidth ?? this.dialogWidth;
    this.showCloseButton =
      data.showCloseButton !== undefined
        ? data.showCloseButton
        : this.showCloseButton;
    this.dialogHeader = data.dialogHeader ?? this.dialogHeader;
    this.confirmationText = data.confirmationText ?? this.confirmationText;

    this.cancelButton = this.loadButtonData(
      data.cancelButton,
      this.cancelButton,
    );
    this.confirmButton = this.loadButtonData(
      data.confirmButton,
      this.confirmButton,
    );
  }

  private loadButtonData(
    buttonData: ConfirmationButtonData,
    targetButton: ConfirmationButtonData,
  ): ConfirmationButtonData {
    if (buttonData !== undefined) {
      if (buttonData === null) {
        targetButton = null;
      } else {
        targetButton.text = buttonData.text ?? targetButton.text;
        targetButton.color = buttonData.color ?? targetButton.color;
        targetButton.variant = buttonData.variant ?? targetButton.variant;
        targetButton.disabled = buttonData.disabled ?? targetButton.disabled;
        targetButton.onClick = buttonData.onClick ?? targetButton.onClick;
      }
    }
    return targetButton;
  }

  closeByHeader() {
    if (this.cancelButton) {
      this.cancelButton.onClick();
    } else {
      this.closeDialog();
    }
  }

  private closeDialog(confirmed = false): void {
    this.dialogRef.close(confirmed);
  }
}
