import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { Component, Input } from '@angular/core';
import {
  Booking,
  BookingCancellationRefund,
} from '../../../../shared/models/booking';
import { isBefore } from 'date-fns';
import { isRefundRuleExpired } from 'src/app/utils/refund.util';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { createBookingLockedDialogConfig } from '../../property-dashboard/utils/booking-locked-dialog.utils';
import { UserReservationCancelDialogComponent } from 'src/app/modules/user/user-reservations/components/user-reservation-detail/user-reservation-cancel-dialog/user-reservation-cancel-dialog.component';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'app-booking-cancellation-policy',
  template: ` <h2 typography variant="h5" color="ink">Cancellation Policy</h2>
    <div class="mt-8 d-flex-column">
      <div class="flex-grow-1 w-100 d-flex-column mb-12">
        <div class="d-flex" *ngIf="refundRule">
          <span typography variant="em2" color="ink">Full refund till</span>
          <span
            class="ml-4"
            typography
            variant="em2"
            [color]="isRefundRuleExpired(refundRule) ? 'red' : 'green'"
            >{{
              refundRule.dateBefore | date: 'EEE h:mm a MM/dd/yyyy OOOO'
            }}</span
          >
        </div>
        <span typography variant="body3">
          <ng-container
            *ngIf="!isRefundRuleExpired(refundRule); else expiredRefundRule"
          >
            Money will be returned to you credit card.
          </ng-container>
          <ng-template #expiredRefundRule>
            Your Vacay is no longer refundable.
          </ng-template>
        </span>
      </div>
      <app-cancellation-policy-chart
        [booking]="booking"
        [refundRule]="refundRule"
      ></app-cancellation-policy-chart>
      <div class="flex-grow-1 w-100 my-16">
        <ng-container *ngIf="isStillRefundable(); else noRefund">
          <span typography variant="body2">
            Your Vacay is no longer refundable past this point. If you need to
            cancel, please do so in the free cancellation period for a full
            refund.
          </span>
        </ng-container>
        <ng-template #noRefund>
          <span typography variant="body2">
            Congratulations! You are paid in full and past eligibility for a
            refund. Enjoy your Vacay!
          </span>
        </ng-template>
      </div>
      <ng-container *ngIf="showCancelButton">
        <div class="w-100">
          <button
            button
            variant="contained"
            color="secondary"
            data-test="cancel-vacay"
            (click)="cancelBooking(booking)"
          >
            Cancel Vacay
          </button>
        </div>
      </ng-container>
    </div>`,
})
export class BookingCancelationPolicyComponent extends AbstractComponent {
  @Input()
  booking: Booking;
  @Input()
  refundRule: BookingCancellationRefund;
  @Input()
  showCancelButton: boolean = false;

  readonly isRefundRuleExpired = isRefundRuleExpired;

  constructor(private readonly dialogService: DialogService) {
    super();
  }

  isStillRefundable() {
    const today = new Date();
    return isBefore(today, this.refundRule?.dateBefore);
  }

  cancelBooking(booking: Booking) {
    if (booking.locked) {
      this.dialogService.open(
        ConfirmationDialogComponent,
        createBookingLockedDialogConfig('canceled'),
      );
    } else {
      this.dialogService.open(UserReservationCancelDialogComponent, {
        data: {
          refundRule: this.refundRule,
          bookingId: booking.id,
          property: booking.property,
        },
      });
    }
  }
}
