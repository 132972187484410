import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKey } from '../../shared/enums/local-storage-key.enum';
import { StorageService } from './storage.service';

/**
 * Service for handling after login redirect to url
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly FALLBACK_URL = '/';

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  /**
   * Set next after login redirect url
   */
  setUrl(url: string, params?: { [key: string]: string }) {
    this.storageService.setSessionItem(StorageKey.REDIRECT_URL, url);
    this.storageService.setSessionItem(
      StorageKey.REDIRECT_QUERY_PARAMS,
      JSON.stringify(params ?? {}),
    );
  }

  /**
   * Reset redirect url, after login homepage will be opened
   */
  resetUrl() {
    this.storageService.removeSessionItem(StorageKey.REDIRECT_URL);
    this.storageService.removeSessionItem(StorageKey.REDIRECT_QUERY_PARAMS);
  }

  /**
   * Get current redirect url
   */
  getUrl(): string {
    return this.storageService.getSessionItem(StorageKey.REDIRECT_URL);
  }

  /**
   * Get current redirect params
   */
  getQueryParams(): { [key: string]: string } {
    const params = this.storageService.getSessionItem(
      StorageKey.REDIRECT_QUERY_PARAMS,
    );
    return params ? JSON.parse(params) : {};
  }

  /**
   * Get current redirect url, if not set, return /
   */
  getUrlOrFallback() {
    return this.getUrl() ?? this.FALLBACK_URL;
  }

  /**
   * Navigate to last redirect url, and reset it
   */
  navigate() {
    const url = this.getUrlOrFallback();
    const urlTree = this.router.parseUrl(url);
    urlTree.queryParams = this.getQueryParams();

    this.router.navigateByUrl(urlTree);
    this.resetUrl();
  }
}
