export enum StorageKey {
  CURRENT_LOCATION = 'current_location',
  TOKEN = 'token',
  /** @Deprecated */
  ACCESS_TOKEN = 'access_token',
  /** @Deprecated */
  EXPIRES_AT = 'expires_at',
  /** @Deprecated */
  SCOPES = 'scopes',
  BOOKING_SIGN_UP = 'booking_sign_up',
  BOOKING_SOCIAL_SIGN_UP = 'booking_social_sign_up',
  UNAUTH_VERIFY_PHONE_ID = 'unauth_verify_phone_id',
  NEXT_REFRESH = 'next_refresh',
  CHAT_TEMPLATES = 'chat_templates',
  PROPERTY_WIZARD_EMAIL_REDIRECT = 'property-wizard-email-redirect',
  REDIRECT_URL = 'redirect_url',
  REDIRECT_QUERY_PARAMS = 'redirect_query_params',
  SIGN_UP_CONSENT_AGREE = 'sign_up_consent_agree',
  IMPACT_PARTNER_ID = 'impact-partner-id',
  PROMO_BANNER_IDS = 'notification-banner-closed-ids',
}
