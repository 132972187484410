import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment,
} from '@angular/router';
import { ForbiddenPageComponent } from './core/components/error-pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './core/components/error-pages/not-found-page/not-found-page.component';
import { LoginPageGuard } from './core/guards/login-page.guard';
import { EmailVerifyingHandlerComponent } from './modules/auth/components/email-verifying-handler.component';
import { LoginWithBookingHandlerComponent } from './modules/auth/components/login-with-booking-handler.component';
import { LoginEmailRouteComponent } from './modules/auth/components/login/login-email-route/login-email-route.component';
import { LoginForgottenRouteComponent } from './modules/auth/components/login/login-forgotten-route/login-forgotten-route.component';
import { LoginRouteComponent } from './modules/auth/components/login/login-route/login-route.component';
import { SignUpEmailRouteComponent } from './modules/auth/components/login/sign-up-email-route/sign-up-email-route.component';
import { SignUpRouteComponent } from './modules/auth/components/login/sign-up-route/sign-up-route.component';
import { SocialLoginHandlerComponent } from './modules/auth/components/login/social-login.handler.component';
import { HomeComponent } from './modules/home/components/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { useToggleGuard } from './core/modules/toggle/toggle.guard';
import { PropertyDashboardRedirectComponent } from './modules/property/shared/property-dashboard-redirect/property-dashboard-redirect.component';

export function propertyDetailRouteMatcher(
  segments: UrlSegment[],
): UrlMatchResult | null {
  if (segments.length < 2 || segments[0].path !== 'properties') {
    return null;
  }

  return {
    consumed: segments,
    posParams: {
      id: segments[1],
    },
  };
}

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'integration',
    loadChildren: () =>
      import('./modules/integration-redirect/integration-redirect.module').then(
        m => m.IntegrationRedirectModule,
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./modules/privacy-policy/privacy-policy.module').then(
        m => m.PrivacyPolicyModule,
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./modules/terms-and-conditions/terms.module').then(
        m => m.TermsModule,
      ),
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./modules/partners/partners.module').then(m => m.PartnersModule),
  },
  {
    path: 'host-benefits',
    loadChildren: () =>
      import('./modules/host-benefits/host-benefits.module').then(
        m => m.HostBenefitsModule,
      ),
  },
  // login & signup routing
  {
    path: 'login',
    component: LoginRouteComponent,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'login/email',
    component: LoginEmailRouteComponent,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'login/forgotten',
    component: LoginForgottenRouteComponent,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'sign-up',
    component: SignUpRouteComponent,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'sign-up/email',
    component: SignUpEmailRouteComponent,
    canActivate: [LoginPageGuard],
  },
  {
    path: 'social/login',
    component: SocialLoginHandlerComponent,
    canActivate: [LoginPageGuard],
  },
  { path: 'email-verified', component: EmailVerifyingHandlerComponent },
  { path: 'login-with-booking', component: LoginWithBookingHandlerComponent },
  {
    path: `properties-dashboard`,
    canActivate: [AuthGuard],
    component: PropertyDashboardRedirectComponent,
    pathMatch: 'full',
  },
  { path: 'forbidden', component: ForbiddenPageComponent },
  { path: 'not-found', component: NotFoundPageComponent },
  // rest of the app is a loaded lazily
  {
    path: 'vmw',
    loadChildren: () =>
      import('./modules/vmw-redirect/vmw-redirect.module').then(
        m => m.VmwRedirectModule,
      ),
  },
  {
    path: 'users/:id',
    loadChildren: () =>
      import('./modules/user/user-profile/user-profile.module').then(
        m => m.UserProfileModule,
      ),
  },
  {
    path: 'vacays',
    loadChildren: () =>
      import('./modules/user/user-reservations/user-reservations.module').then(
        m => m.UserReservationsModule,
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/user/user-settings/user-settings.module').then(
        m => m.UserSettingsModule,
      ),
  },
  {
    path: 'property-wizard',
    loadChildren: () =>
      import('./modules/property/property-wizard/property-wizard.module').then(
        m => m.PropertyWizardModule,
      ),
  },
  {
    path: 'user-verification',
    loadChildren: () =>
      import('./modules/user/user-verification/user-verification.module').then(
        m => m.UserVerificationModule,
      ),
  },
  useToggleGuard('devPages', {
    path: 'demo',
    loadChildren: () =>
      import('./modules/demo/demo.module').then(m => m.DemoModule),
  }),
  useToggleGuard('devPages', {
    path: 'documentation/components',
    loadChildren: () =>
      import('@components/documentation/documentation.module').then(
        m => m.DocumentationModule,
      ),
  }),
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/property/property-search/property-search.module').then(
        m => m.PropertySearchModule,
      ),
  },
  {
    path: 'bookings/:id',
    loadChildren: () =>
      import(
        './modules/property/property-booking/property-booking.module'
      ).then(m => m.PropertyBookingModule),
  },
  {
    path: 'properties/:propertyId/dashboard',
    loadChildren: () =>
      import(
        './modules/property/property-dashboard/property-dashboard.module'
      ).then(m => m.PropertyDashboardModule),
  },
  {
    path: 'properties/:propertyId/edit',
    loadChildren: () =>
      import('./modules/property/property-edit/property-edit.module').then(
        m => m.PropertyEditModule,
      ),
  },
  {
    path: 'properties',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/property/property-list/property-list.module').then(
        m => m.PropertyListModule,
      ),
  },
  {
    matcher: propertyDetailRouteMatcher,
    loadChildren: () =>
      import('./modules/property/property-detail/property-detail.module').then(
        m => m.PropertyDetailModule,
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then(m => m.AccountModule),
  },
  useToggleGuard('chat', {
    path: 'chat',
    loadChildren: () =>
      import('./modules/chat/chat.module').then(m => m.ChatModule),
  }),
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
