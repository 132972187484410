import { kebabCase } from 'lodash-es';
import { environment } from 'src/environments/environment';

export function createUrlSlug(url: string): string {
  // kebabCase doesn't remove emojis, so we handle it manually
  return kebabCase(kebabCase(url).replace(/[^\x00-\x7F]/g, ''));
}

export function createUrlWithParams(
  url: string,
  params: Record<string, string>,
): string {
  const urlParams = new URLSearchParams(params);
  return `${url}?${urlParams.toString()}`;
}

export function getHostfullyAuth0RedirectUrl(state: string): string {
  return createUrlWithParams(environment.integrations.hostfully.auth0Url, {
    clientId: environment.integrations.hostfully.clientId,
    redirectUri: environment.integrations.hostfully.redirectUri,
    state: state,
    scope: 'FULL',
    grantType: 'REFRESH_TOKEN',
  });
}
