import { Property, PropertyPreview } from './shared/models/property';
import { createUrlSlug } from './utils/url.util';

type PropertyWizardTab =
  | 'property-detail'
  | 'pricing'
  | 'personal-info'
  | 'pair-account'
  | 'setup-withdrawals'
  | 'bank-accounts'
  | 'availability'
  | 'amenities'
  | 'rules'
  | 'insurance-cancellation'
  | 'protection'
  | 'publish';
type BookingPage = 'summary' | 'payment' | 'complete' | 'verification';

/**
 * Definition of URLs used in app
 */
export class Url {
  static readonly INDEX = '/';

  static readonly LOGIN = '/login';
  static readonly SIGN_UP = '/sign-up';
  static readonly LOGIN_EMAIL = '/login/email';

  static readonly PROPERTIES = '/properties';
  static readonly PROPERTIES_ = (
    property: Property | PropertyPreview,
  ): string => {
    let url = `/properties/${property.id}`;
    let firstSegment = '';
    let secondSegment = '';

    if (property['propertyType']) {
      firstSegment = property['propertyType'];
    }

    if (
      property.address?.city ||
      property.address?.state ||
      property.address?.country
    ) {
      firstSegment += firstSegment !== '' ? '-in-' : '';
      if (property.address.city) {
        firstSegment += `-${property.address.city}`;
      }
      if (property.address.state) {
        firstSegment += `-${property.address.state}`;
      }
      if (property.address.country) {
        firstSegment += `-${property.address.country}`;
      }
    }

    if (property.headline) {
      secondSegment = property.headline;
    }

    if (firstSegment) {
      url += `/${createUrlSlug(firstSegment)}`;
    }
    if (secondSegment) {
      url += `/${createUrlSlug(secondSegment)}`;
    }
    return url;
  };

  static readonly PROPERTY_DASHBOARD = '/properties-dashboard'; // Only redirect link for calendar
  static readonly PROPERTY_DASHBOARD_ = (propertyId: string) =>
    `/properties/${propertyId}/dashboard`;

  static readonly PROPERTIES_EDIT_ = (propertyId: string) =>
    `/properties/${propertyId}/edit`;
  static readonly PROPERTY_BOOKING_SYNC_ = (propertyId: string) =>
    `/properties/${propertyId}/edit/booking-sync`;
  static readonly PROPERTY_EDIT_RATES_ = (propertyId: string) =>
    `/properties/${propertyId}/edit/rates`;

  static readonly PROPERTY_WIZARD = '/property-wizard';
  static readonly PROPERTY_WIZARD_ = (
    propertyId: string,
    tab: PropertyWizardTab,
  ) => `/property-wizard/${propertyId}/${tab}`;

  static readonly BOOKINGS_ = (bookingId: string, page: BookingPage) =>
    `/bookings/${bookingId}/${page}`;

  static readonly VACAYS = '/vacays';
  static readonly VACAYS_HISTORY = '/vacays/history';
  static readonly VACAYS_ = (bookingId: string) => `/vacays/${bookingId}`;
  static readonly VACAYS_REVIEW = (bookingId: string) =>
    `/vacays/${bookingId}/review`;
  static readonly SEARCH = '/search';

  static readonly USERS_ = (userId: string, profileTab?: string) =>
    `/users/${userId}` + (profileTab ? `/${profileTab}` : '');
  static readonly USERS_SELF = '/users/self';

  static readonly ACCOUNT = '/account';

  static readonly ACCOUNT_WITHDRAW_SETTINGS = '/account/withdraw-settings';
  static readonly ACCOUNT_WITHDRAW_MONEY = '/account/withdraw-money';
  static readonly ACCOUNT_WITHDRAW_SUCCESS = '/account/withdraw-success';

  static readonly CHAT = '/chat';
  static readonly CHAT_ = (conversationId: string) => `/chat/${conversationId}`;

  static readonly SETTINGS = '/settings';
  static readonly SETTINGS_GENERAL = '/settings/general';
  static readonly SETTINGS_SECURITY = '/settings/security';
  static readonly SETTINGS_PAYMENT = '/settings/payment';
  static readonly SETTINGS_INTEGRATION = '/settings/integrations';
  static readonly SETTINGS_NOTIFICATION = '/settings/notification';

  static readonly SETTINGS_INTEGRATION_HOSTFULLY_EDIT =
    '/settings/integrations/hostfully/edit';
  static readonly SETTINGS_INTEGRATION_HOSTFULLY_WIZARD =
    '/settings/integrations/hostfully/wizard';

  static readonly SETTINGS_INTEGRATION_NEXTPAX_EDIT =
    '/settings/integrations/nextpax/edit';
  static readonly SETTINGS_INTEGRATION_NEXTPAX_WIZARD =
    '/settings/integrations/nextpax/wizard';

  static readonly SETTINGS_INTEGRATION_HOSTAWAY_EDIT =
    '/settings/integrations/hostaway/edit';
  static readonly SETTINGS_INTEGRATION_HOSTAWAY_WIZARD =
    '/settings/integrations/hostaway/wizard';

  static readonly SETTINGS_INTEGRATION_STREAMLINE_EDIT =
    '/settings/integrations/streamline/edit';
  static readonly SETTINGS_INTEGRATION_STREAMLINE_WIZARD =
    '/settings/integrations/streamline/wizard';

  static readonly SETTINGS_INTEGRATION_BOOST_EDIT =
    '/settings/integrations/boost/edit';
  static readonly SETTINGS_INTEGRATION_BOOST_WIZARD =
    '/settings/integrations/boost/wizard';

  static readonly SETTINGS_INTEGRATION_LIVELIST_EDIT =
    '/settings/integrations/livelist/edit';
  static readonly SETTINGS_INTEGRATION_LIVELIST_WIZARD =
    '/settings/integrations/livelist/wizard';

  static readonly USER_VERIFICATION = '/user-verification';

  static readonly VERIFICATION_REDIRECT = '/verification-redirect';

  static readonly DEMO_TEMPLATE = '/demo/template';
  static readonly COMPONENT_DOCUMENTATION = '/documentation/components';

  static readonly HOST_BENEFITS = '/host-benefits';
  static readonly PARTNERS = '/partners';
  static readonly TERMS_AND_CONDITIONS = '/terms-and-conditions';
  static readonly PRIVACY_POLICY = '/privacy-policy';

  static readonly NOT_FOUND = '/not-found';
  static readonly FORBIDDEN = '/forbidden';
}

export const API_USER_BASE = '/user/api/v1';
