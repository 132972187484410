import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatIntegrator' })
export class IntegratorFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value?.toLowerCase() === 'boost') {
      return 'Boost/LiveList';
    }

    const formattedValue =
      value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    return formattedValue.replace('_', '');
  }
}
