import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  filter,
  first,
  map,
} from 'rxjs';
import { StorageKey } from 'src/app/shared/enums/local-storage-key.enum';
import { StorageService } from './storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToggleService } from '../modules/toggle/toggle.service';
import { PromoBannerInstance } from '../model/promo-banner-instance';
import { PromoBannerSignupComponent } from '../components/promo-banner/banners/promo-banner-signup.component';

const ENABLED_BANNERS: PromoBannerInstance[] = [
  {
    id: 'signup-reward-bonus',
    pages: ['/properties/*', '/search*'],
    component: PromoBannerSignupComponent,
    toggle: 'signUpRewardPointsTopBanner',
  },
];

@Injectable({
  providedIn: 'root',
})
export class PromoBannerService {
  closingBanners = new Set<string>();
  private readonly onNavigation$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
  );
  private readonly closedBannerIds$ = new ReplaySubject<
    PromoBannerInstance['id'][]
  >(1);

  constructor(
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly toggleService: ToggleService,
  ) {
    const storate = storageService.getItem(StorageKey.PROMO_BANNER_IDS);
    if (storate) {
      this.closedBannerIds$.next(JSON.parse(storate));
    } else {
      this.closedBannerIds$.next([]);
    }
  }

  getBanners$(): Observable<PromoBannerInstance[]> {
    return combineLatest([this.onNavigation$, this.closedBannerIds$]).pipe(
      map(([event, closedBannerIds]) => {
        return ENABLED_BANNERS.filter(
          banner =>
            this.toggleService.isEnabled(banner.toggle) &&
            !closedBannerIds.includes(banner.id) &&
            this.isPageMatched(banner, event.url),
        );
      }),
    );
  }

  closeBanner(id: string) {
    this.closedBannerIds$.pipe(first()).subscribe(closedBannerIds => {
      const allIds = [...closedBannerIds, id];
      this.storageService.setItem(
        StorageKey.PROMO_BANNER_IDS,
        JSON.stringify(allIds),
      );
      this.closedBannerIds$.next(allIds);
      this.closingBanners.delete(id);
    });
  }

  private isPageMatched(banner: PromoBannerInstance, url: string) {
    const pages = banner.pages;
    if (pages.length === 0) {
      return true;
    }
    return pages.some(pattern => {
      if (pattern.includes('*')) {
        const regexPattern =
          '^' + pattern.split('*').map(this.escapeRegExp).join('.*') + '$';
        const regex = new RegExp(regexPattern);
        return regex.test(url);
      } else {
        return url === pattern;
      }
    });
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
  }
}
