import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import { AuthService } from '../../../../core/services/auth.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { StorageKey } from '../../../../shared/enums/local-storage-key.enum';
import { AdditionalRegistrationClosedError } from '../../../../utils/errors';
import { NotificationService } from '../../../../core/services/notification.service';
import { RedirectService } from '../../../../core/services/redirect.service';
import { StorageService } from '../../../../core/services/storage.service';
import { Url } from '../../../../url';
import { WelcomeComponent } from '../post-registration-dialogs/welcome/post-registration-dialogs/welcome/welcome.component';

/**
 * Handler component for authentication with social provider
 *
 * After redirect from facebook, google-oauth2 or passwordless sms login, this component parses hash in URL
 * and verifies if user exists in mongodb.
 */
@Component({
  selector: 'app-social-login',
  template: '',
})
export class SocialLoginHandlerComponent extends AbstractComponent {
  constructor(
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly storageService: StorageService,
  ) {
    super();
    this.authService.handleSocialLogin().subscribe(
      user => {
        let url = this.storageService.getItem(
          StorageKey.BOOKING_SOCIAL_SIGN_UP,
        );
        let queryParams = {};

        if (!url) {
          url = this.redirectService.getUrlOrFallback();
          queryParams = this.redirectService.getQueryParams();
        }

        this.redirectService.resetUrl();
        this.router.navigate([url], { queryParams });

        if (!/.*\/bookings\/.*\/summary.*/.test(url) && user.isNewUser) {
          this.dialogService.clearAndOpen(WelcomeComponent);
        }
      },
      error => {
        if (error instanceof AdditionalRegistrationClosedError) {
          this.notificationService.showNotification({
            text: 'Your registration was not completed',
            color: 'error',
            duration: 5000,
          });
          this.router.navigate([Url.LOGIN]);
          return;
        }
        // TODO: redirect to main page / or error?
        this.router.navigate([Url.LOGIN]);
      },
    );
  }
}

/**
 * Button for Google login. Content is button text.
 */
@Component({
  selector: 'app-login-google',
  template: `<button
    button
    type="button"
    variant="contained"
    color="secondary"
    class="d-flex justify-content-center w-100"
    (click)="loginWithGoogle()"
  >
    <ng-content></ng-content>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLoginGoogleComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  loginWithGoogle() {
    const redirectUrl = this.router.url;
    if (redirectUrl.includes('bookings')) {
      this.storageService.setItem(
        StorageKey.BOOKING_SOCIAL_SIGN_UP,
        redirectUrl,
      );
    }
    this.authService.signInGoogle();
  }
}
