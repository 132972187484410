<dialog-wrapper [width]="dialogWidth" [position]="(largeScreen$ | async) === true ? 'center' : 'bottom'">
  <dialog-header [title]="dialogHeader" [showClose]="showCloseButton" (dialogClose)="closeByHeader()"></dialog-header>

  <dialog-content>
    <div typography color="ink" class="mb-32" [innerHTML]="confirmationText"></div>
  </dialog-content>

  <dialog-actions>
    <div class="row">
      <div *ngIf="confirmButton" class="order-last order-md-first pr-0 pr-md-16" [ngClass]="{'col-md-8 col-6' : cancelButton, 'col-12': !cancelButton}">
        <button
          button
          type="button"
          class="w-100"
          [color]="confirmButton.color"
          [variant]="confirmButton.variant"
          [disabled]="confirmButton.disabled"
          (click)="confirmButton.onClick()"
        >
          {{ confirmButton.text }}
        </button>
      </div>
      <div *ngIf="cancelButton" class="order-first order-md-last pr-md-0 pr-16" [ngClass]="{'col-md-4 col-6' : cancelButton, 'col-12': !cancelButton}">
        <button
          button
          type="button"
          class="w-100"
          [color]="cancelButton.color"
          [variant]="cancelButton.variant"
          (click)="cancelButton.onClick()"
        >
          {{ cancelButton.text }}
        </button>
      </div>
    </div>
  </dialog-actions>
</dialog-wrapper>
