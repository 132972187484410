<div *ngIf="showBanner$ | async" class="promo-banner-item info" (click)="onSignUp()">
  <icon
    variant="reward_point"
    class="d-block mr-8"
    size="inherit"
  ></icon>

  <div>
    <span class="underline">Sign up</span> and save <b>${{ getSaveAmount | number }}</b> on your first booking
  </div>

  <button
    button
    variant="inline"
    (click)="onClose($event)"
    class="close-button"
  >
    <icon variant="cross" size="inherit" color="white" class="d-block"></icon>
  </button>
</div>
