import { environment } from 'src/environments/environment';

export class AppConstants {
  static readonly PROPERTY_IMAGE_MIN_WIDTH = 600;
  static readonly PROPERTY_IMAGE_MIN_HEIGHT = 600;
  // TODO - temp max resolution, we have to find out what actual width/height cap is.
  static readonly PROPERTY_IMAGE_MAX_WIDTH = 5120;
  static readonly PROPERTY_IMAGE_MAX_HEIGHT = 2880;
  /** Min and max number of property images */
  static readonly PROPERTY_IMAGES_MIN = 4;
  static readonly PROPERTY_IMAGES_MAX = 100;
  static readonly SEARCH_FIRST_PAGE_SIZE = 50;

  static readonly HOSTFULLY_AUTH_STATE = `hostfullyAuth:${environment.name}`;
  static readonly HOSTFULLY_RECONNECT_AUTH_STATE = `hostfullyReconnectAuth:${environment.name}`;
}
